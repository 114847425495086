.checkbox-container {
  position: relative;
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid black;
  box-sizing: border-box;
}

.checkbox-container input {
  position: absolute;
  display: none;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.grayblock,
.checkmark:after {
  content: "";
  display: block;
}

.checkbox-container .checkmark:after {
  width: 4px;
  height: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-container .grayblock {
  border-radius: 2px;
  background-color: gray;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
}
