@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Beausite Detail";
  src: url("assets/fonts/BeausiteDetail-Regular.otf");
}

body {
  margin: 0;
  font-family:
    "Arial",
    "san-serif",
    "Martel Sans",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

[type="button"] {
  -webkit-appearance: none;
}

.quick-pdf-image-class {
  transform: scale(1, 0.78);
  -moz-transform: scale(1, 0.78);
  transform-origin: center top;
  -moz-transform-origin: center top;
}

.page-preview-container {
  overflow: hidden;
  border: 1px solid;
}

.page-preview-container embed {
  position: absolute;
}

.dashboard-table tr .actions-component,
.wizard-template .wizard-template-actions {
  opacity: 0;
}

.dashboard-table tr:hover .actions-component,
.wizard-template .page-row:hover .wizard-template-actions {
  opacity: 1;
}
.dashboard-table .dashboard-table-title {
  display: block;
  max-width: 300px;
}

.carousel-instance .carousel .absolute.left-0 {
  left: 5px;
}

.carousel-instance .carousel .absolute.right-0 {
  right: 5px;
}

.carousel-instance .carousel .absolute.left-0,
.carousel-instance .carousel .absolute.right-0 {
  top: 65px;
}

.carousel-instance .carousel .absolute.left-0 button,
.carousel-instance .carousel .absolute.right-0 button {
  background: #ffffff;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-instance .carousel .absolute.left-0 button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.carousel-instance .carousel .absolute.right-0 button {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.selected-carousel .carousel .absolute.left-0,
.selected-carousel .carousel .absolute.right-0 {
  top: 65px;
}

.selected-carousel .carousel .absolute.left-0 {
  left: -30px;
}
.selected-carousel .carousel .absolute.right-0 {
  right: -30px;
}

.number-shadow {
  text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
}

@keyframes skeleton-instance-transition {
  0% {
    background-color: rgb(170, 172, 178, 1);
  }
  50% {
    background-color: rgb(170, 172, 178, 0.1);
  }
  100% {
    background-color: rgb(170, 172, 178, 1);
  }
}

.loading-instances-image {
  pointer-events: none;
}

.loading-instances-image .page-preview-container:after,
.loading-instances-image .instance-summary .instance-image-area:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 5;
}

.loading-instances-image .page-preview-container .embed-pdf-template,
.loading-instances-image .instance-summary .instance-description-area {
  opacity: 0;
}
.loading-instances-image .instance-summary .instance-image-area {
  background-image: none !important;
}

.loading-instances-image .instance-image .instance-image-bg,
.loading-instances-image .page-preview-container:after,
.loading-instances-image .instance-summary .instance-image-area:after {
  transition: all 1.2s;
  border: 2px dotted #c3c3c3;
  background-image: none !important;
  animation: skeleton-instance-transition infinite 1s;
}

.dashboard .component-table-row .list-actions,
.manage-roles .component-table-row .manage-roles-list-actions,
.user-admin .component-table-row .user-list-actions {
  opacity: 0;
}

.dashboard .component-table-row:hover .list-actions,
.manage-roles .component-table-row:hover .manage-roles-list-actions,
.user-admin .component-table-row:hover .user-list-actions {
  opacity: 1;
}

.carousel-component .slider-button {
  opacity: 0;
}

.carousel-component:hover .slider-button {
  opacity: 1;
}

.dashboard .component-table-row .list-actions {
  opacity: 0;
}

.dashboard .component-table-row:hover .list-actions {
  opacity: 1;
}

.dashboard .component-table-row .list-actions [type="button"] {
  -webkit-appearance: none;
}

.rdw-dropdown-wrapper {
  width: 150px;
}

.rdw-dropdown-optionwrapper {
  width: 100%;
}

.ck .ck-content {
  min-height: 400px;
  resize: vertical;
}

.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

.gc-viewer {
  container-type: normal !important;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.remove-arrow {
  -moz-appearance: textfield;
}
